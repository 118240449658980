import accessory from 'assets/images/report_icons/Accessory.png';
import alarmFrequency from 'assets/images/report_icons/AlarmFrequency.png';
import attendance from 'assets/images/report_icons/Attendance.png';
import alarmDuration from 'assets/images/report_icons/Duration.png';
import careMinutes from 'assets/images/report_icons/CareMinutes.png';
import excesiveResponseTime from 'assets/images/report_icons/ExcessiveResponseTime.png';
import responseTimeAndTimeOfCareGiven from 'assets/images/report_icons/Graph2.png';
import listOfActions from 'assets/images/report_icons/ListOfActions.png';
import listOfOperationalAlarms from 'assets/images/report_icons/ListOfOperationalAlarms.png';
import numberOfAlarmsPerIdType from 'assets/images/report_icons/NumberOfAlarmsPerType.png';
import numberOfCarers from 'assets/images/report_icons/NumberOfAttendants.png';
import numberOfCarersPlanning from 'assets/images/report_icons/NumberOfAttendantsPlanning.png';
import pager from 'assets/images/report_icons/PagerReport.png';
import scheduledActions from 'assets/images/report_icons/PlannedActions.png';
import plannedVsActualActions from 'assets/images/report_icons/PlannedVsActualActions.png';
import presenceByGender from 'assets/images/report_icons/PresenceByGender.png';
import responseTimeAndTimeOfCareGivenPerUser from 'assets/images/report_icons/ResponseAndCareTimePerUser.png';
import responseTimePerShift from 'assets/images/report_icons/ResponseTimePerShift.png';
import security from 'assets/images/report_icons/Security.png';
import timeToResponse from 'assets/images/report_icons/TimeToResponse.png';
import timeTracking from 'assets/images/report_icons/TimeTracking.png';
import travelTime from 'assets/images/report_icons/TravelTime.png';
import listOfAlarms from 'assets/images/report_icons/alarmlog.png';
import dateFormat from 'dateformat';
import {
    DateRangeMode,
    ReportClassId,
    SliceRangeMode,
    TemplateType,
} from '../../helpers/enums';
import * as blocks from './../../components/creator/blocks';

const dateRangeCriteriaBlock = () => {
    return {
        range: DateRangeMode.LastNDays,
        days: 7,
        min: dateFormat(new Date(), "yyyy-mm-dd'T'HH:MM"),
        max: dateFormat(new Date(), "yyyy-mm-dd'T'HH:MM"),
        toduse: false,
        todfrom: '08:00',
        todto: '16:00',
    };
};

const sliceRangeCriteriaBlock = () => {
    return {
        mode: SliceRangeMode.LastNSlices,
        slices: 7,
        current: false,
        min: dateFormat(new Date(), 'yyyy-mm-dd'),
        max: dateFormat(new Date(), 'yyyy-mm-dd'),
    };
};

const fieldIdValueRangeCriteriaBlock = () => {
    return {
        f: '',
    };
};

const alarmTypeCriteriaBlock = () => {
    return {
        ftvs: [],
    };
};

const userCriteriaBlock = () => {
    return {
        use: false,
        n: '',
    };
};

const securityUserCriteriaBlock = () => {
    return {
        ns: [],
    };
};

const alarmActivationDurationBlock = () => {
    return {
        min: '',
    };
};

const targetsCriteriaBlock = () => {
    return {
        t: [],
    };
};

const fieldInclusionCriteriaBlock = () => {
    return {
        fn: [],
    };
};

const builtInTemplates = [
    {
        reportClassId: ReportClassId.AlarmDuration,
        title: 'Reports.AlarmDuration',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: alarmDuration,
    },
    {
        reportClassId: ReportClassId.AlarmFrequencyDay,
        title: 'Reports.AlarmFrequencyDay',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: alarmFrequency,
    },
    {
        reportClassId: ReportClassId.AlarmFrequencyWeek,
        title: 'Reports.AlarmFrequencyWeek',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: alarmFrequency,
    },
    {
        reportClassId: ReportClassId.AlarmHistory,
        title: 'Reports.AlarmHistory',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: listOfAlarms,
    },
    {
        reportClassId: ReportClassId.Attendance,
        title: 'Reports.Attendance',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: attendance,
    },
    {
        reportClassId: ReportClassId.Accessory,
        title: 'Reports.Accessory',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            u: userCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: accessory,
    },
	{
        reportClassId: ReportClassId.CareMinutes,
        title: 'Reports.CareMinutes',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: careMinutes,
    },
    {
        reportClassId: ReportClassId.Security,
        title: 'Reports.Security',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            u: userCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: security,
    },
    {
        reportClassId: ReportClassId.ExcessiveResponseTime,
        title: 'Reports.ExcessiveResponseTime',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
            dur: alarmActivationDurationBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: excesiveResponseTime,
    },
    {
        reportClassId: ReportClassId.ListOfActions,
        title: 'Reports.ListOfActions',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            tg: targetsCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: listOfActions,
    },
    {
        reportClassId: ReportClassId.ListOfAlarms,
        title: 'Reports.ListOfAlarms',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
            u: userCriteriaBlock(),
            fi: fieldInclusionCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: listOfAlarms,
    },
    {
        reportClassId: ReportClassId.ListOfOperationalAlarms,
        title: 'Reports.ListOfOperationalAlarms',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: listOfOperationalAlarms,
    },
    {
        reportClassId: ReportClassId.NumberOfAlarmsPerId,
        title: 'Reports.NumberOfAlarmsPerId',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
			t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfAlarmsPerIdType,
    },
    {
        reportClassId: ReportClassId.NumberOfAlarmsPerType,
        title: 'Reports.NumberOfAlarmsPerType',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfAlarmsPerIdType,
    },
    {
        reportClassId: ReportClassId.NumberOfCarers,
        title: 'Reports.NumberOfCarers',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfCarers,
    },
    {
        reportClassId: ReportClassId.NumberOfCarersPlanning,
        title: 'Reports.NumberOfCarersPlanning',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            tg: targetsCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfCarersPlanning,
    },
    {
        reportClassId: ReportClassId.PagerReport,
        title: 'Reports.PagerReport',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            su: securityUserCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: pager,
    },
    {
        reportClassId: ReportClassId.PlannedVsActualActions,
        title: 'Reports.PlannedVsActualActions',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: plannedVsActualActions,
    },
    {
        reportClassId: ReportClassId.PresenceByGender,
        title: 'Reports.PresenceByGender',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: presenceByGender,
    },
    {
        reportClassId: ReportClassId.ResponseTimeAndTimeOfCareGiven,
        title: 'Reports.ResponseTimeAndTimeOfCareGiven',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: responseTimeAndTimeOfCareGiven,
    },
    {
        reportClassId: ReportClassId.ResponseTimeAndTimeOfCareGivenPerUser,
        title: 'Reports.ResponseTimeAndTimeOfCareGivenPerUser',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: responseTimeAndTimeOfCareGivenPerUser,
    },
	{
        reportClassId: ReportClassId.ResponseTimePerShift,
        title: 'Reports.ResponseTimePerShift',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
			t: alarmTypeCriteriaBlock(),
			dur: alarmActivationDurationBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: responseTimePerShift,
    },
    {
        reportClassId: ReportClassId.ScheduledActions,
        title: 'Reports.ScheduledActions',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            tg: targetsCriteriaBlock(),
            su: securityUserCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: scheduledActions,
    },
    {
        reportClassId: ReportClassId.TimeToResponse,
        title: 'Reports.TimeToResponse',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: timeToResponse,
    },
    {
        reportClassId: ReportClassId.TimeTracking,
        title: 'Reports.TimeTracking',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
            t: alarmTypeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: timeTracking,
    },
    {
        reportClassId: ReportClassId.TravelTime,
        title: 'Reports.TravelTime',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            u: userCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: travelTime,
    },
    {
        reportClassId: ReportClassId.UserWorkload,
        title: 'Reports.UserWorkload',
        criteriaJson: {
            d: dateRangeCriteriaBlock(),
            fid: fieldIdValueRangeCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfCarers,
    },
    {
        reportClassId: ReportClassId.UserWorkloadPlanning,
        title: 'Reports.UserWorkloadPlanning',
        criteriaJson: {
            s: sliceRangeCriteriaBlock(),
            tg: targetsCriteriaBlock(),
        },
        type: TemplateType.BuiltIn,
        icon: numberOfCarersPlanning,
    },
];

const getBuiltInTemplates = () => {
    return {
        success: true,
        data: builtInTemplates,
    };
};

export default getBuiltInTemplates;

const criteriaPropertyToBlockMap = {
    d: blocks.DateRange,
    s: blocks.SliceRange,
    fid: blocks.IdRange,
    t: blocks.AlarmType,
    u: blocks.User,
    dur: blocks.AlarmActivationDuration,
    tg: blocks.Target,
    su: blocks.SecurityUser,
    fi: blocks.FieldInclusion,
};

export const getBlocksByCriteriaJson = (criteriaJson) => {
    const properties = Object.getOwnPropertyNames(criteriaJson);
    const mappedBlocks = properties.map((property) => {
        return criteriaPropertyToBlockMap[property];
    });
    return [blocks.Properties, ...mappedBlocks];
};
